<template>
  <div class="row" ref="practicalMatterForm">


    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
                  vid="forum"
                  rules="required"
                  name="The Edition"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="forum_id"
                    size="large"
                    filterable
                    clearable
                    placeholder="Edition"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Edition'"
                    :list="builderData.forumList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.forum_id">
                  </fg-select>
                </ValidationProvider>

            <ValidationProvider
              vid="title"
              rules="required"
              name="The title"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Title"
                        name="title"
                        fou
                        v-model="formData.title">
              </fg-input>
            </ValidationProvider>

          <div class="form-group">
            <label>Description</label>
            <editor
              v-model="formData.description"
              :api-key="editorKey"
              :init='editorConfig'
            />
          </div>
            <div class="form-group">
                <label>Icon</label>
                <el-tooltip placement="right" >
                  <div slot="content"> (548 * 548)</div>
                  <span style="margin: 0 5px;">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-question-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path
                      d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                  </svg>
                  </span>
                </el-tooltip>
                <single-file-uploader-file-manager
                  :preview-width="'200px'"
                  :preview-height="'200px'"
                  v-model="formData.icon"
                  :accepted-files="imagesExtensions">
                </single-file-uploader-file-manager>
            </div>

            <div class="form-group">
                  <label>Images</label>
                  <el-tooltip placement="right" >
                    <div slot="content"> (548 * 548)</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <multiple-file-uploader-file-manager
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    v-model="formData.images"
                    :filesNumber="1000"
                    :accepted-files="imagesExtensions">
                  </multiple-file-uploader-file-manager>
                </div>

                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>


          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>&nbsp;

            <l-button @click="$router.push('/practical_matters/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option, Tooltip} from 'element-ui';
import {mapActions} from "vuex";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import SingleFileUploaderFileManager from "../../../components/FileManager/SingleFileUploaderFileManager";
import MultipleFileUploaderFileManager from "../../../components/FileManager/MultipleFileUploaderFileManager";
import FgSelect from "@/components/Inputs/formGroupSelect";

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    SingleFileUploaderFileManager,
    MultipleFileUploaderFileManager,
    PrimeUploader,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    LSwitch,
    FgSelect,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      imagesExtensions: this.getImagesExtensions(),
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      formData: {
        title: "",
        description: "",
        images: [],
        forum_id: "",
        icon: "",
        is_active : true
      },
      builderData: {
        forumList: [],
      },

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.mediaForm
    });

    let data = {}
    this.axios.post("practical_matters/builder", data).then((response) => {
      this.builderData.forumList = response.data.forums;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Practical Matter";
        this.getMediaInfo();
      } else {
        this.editMode = false;
        this.formTitle = "Add Practical Matter";
        this.loader.hide();
      }
    }).catch((error) => {
      console.error(error);
    })

    
  },


  methods: {

    ...mapActions({
      indexAction: 'global/index'
    }),

    getMediaInfo() {
      let data = {
          'id' : this.id,
      };
      this.axios.post("practical_matters/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Practical Matter Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("practical_matters/update/" + this.id, this.formData);
        successMessage = "Practical Matter Item Updated Successfully";
      } else {
        request = this.axios.post("practical_matters/create", this.formData);
        successMessage = "Practical Matter Item Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        });
        this.indexAction().then(() => {
          this.$router.push("/practical_matters/list");
        })
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      });
    }

  }
}
</script>

<style>
</style>
