<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Practical Matter List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['practical_matters/create'])"
                       :to="'/practical_matters/create'"
                       class="btn btn-info btn-wd" wide>
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>

         <!--    filters    -->
         <div class="row col-12 mb-2">
          <div class="col-md-4 col-sm-4">
            <fg-select
              size="large"
              filterable
              clearable
              placeholder="Select Edition"
              :input-classes="'select-default'"
              :list="filterBuilders.forums"
              :listItemLabel="'name'"
              :listItemValue="'id'"
              v-model="filters.forum_id">
            </fg-select>
          </div>

          <div class="col-md-2 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">Filter Result</l-button>
          </div>
        </div>
        <!--  end  filters    -->

        <div>
          <general-data-table
            ref="table"
            :key="componentKey"
            :api-url="'practical_matters/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['ar/practical_matters/update'])"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/practical_matters/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i>
                </router-link>

                <a v-if="$store.getters['auth/checkAccess']('practical_matters/delete')" v-tooltip.top-center="'Delete'"
                  class="btn-danger btn-simple btn-link"
                    @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i>
                </a>

              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this practical matter item?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";

export default {
  components: {
    NLPagination,
    DeleteModal,
    LSwitch,
    FgSelect,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
        {label: 'Edition', value: 'forum.name', minWidth: '200', align: 'center'},
      ],
      fixedColumn: null,
      deleteModalVisibility: false,
      toDeleteId: undefined,
      url : process.env.VUE_APP_SERVER_IMAGE_URL,
      componentKey: 0,
      filterBuilders: {
        forums: []
      },
      filters: {
        forum_id: null,
        type: 'normal'
      },
    }

  },
  created() {
      this.responsiveViewPort();
  },
  async mounted() {
    let response = await this.axios.post("practical_matters/builder");
    this.filterBuilders.forums = response.data.forums;
  },
  methods: {

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("practical_matters/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Practical Matter Item deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    responsiveViewPort(){
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.fixedColumn = null;
        }else{
            this.fixedColumn = 'right';
        }
    },
    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      this.$refs.table.getData("updateData");
    },
  },
}
</script>
<style>
</style>
